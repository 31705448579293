html, body, #root, .App {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #4c7486;
  color: white;
}

header .logo img {
  height: 50px;
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.hero {
  background-size: cover;
  background-position: center;
  color: white;
  padding: 37% 0 0 0;
  text-align: center;
}

.hero .btn-primary {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.hero .btn-primary:hover {
  background-color: #0056b3;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.services {
  padding: 60px 0;
  background-color: #fff;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.service-item img {
  max-width: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.service-item img:hover {
  transform: scale(1.05);
}

.service-item h3 {
  margin-top: 10px;
  font-size: 1.25rem;
}

.service-item p {
  margin-top: 10px;
  color: #666;
}

.testimonials {
  padding: 60px 0;
  background-color: #f1f1f1;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.carousel-item {
  flex: 0 0 80%;
  scroll-snap-align: center;
  margin: 0 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.carousel-item:hover {
  transform: scale(1.05);
}

.carousel-item img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.about {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.about h2 {
  text-align: center;
  margin-bottom: 20px;
}

.about-content {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.about-us-image {
  flex: 1;
  max-width: 25%;
  height: auto;
  border-radius: 10px;
}

.about-text {
  flex: 1;
}

.about p {
  color: #666;
  margin-bottom: 10px;
}

.contact {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.contact h2 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007BFF;
}

form .btn-primary {
  align-self: flex-start;
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

form .btn-primary:hover {
  background-color: #0056b3;
}

footer {
  background-color: #4c7486;
  color: white;
  text-align: center;
  padding: 20px 0;
  margin-top: 20px;
}

footer .container {
  max-width: 1200px;
  margin: 0 auto;
}
