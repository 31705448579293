.hero {
    background-size: cover;
    background-position: center;
    color: white;
    padding: 37% 0 0 0;
    text-align: center;
    position: relative;
    margin-top: 90px;
}

.hero .container {
    position: relative;
    z-index: 1;
}

.hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Adjust opacity for darkness */
    z-index: 0;
}

.hero-content {
    position: relative;
    display: inline-block;
    background: rgba(0, 0, 0, 0.6); /* Translucent background */
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
}

.hero .btn-primary {
    background-color: #007BFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.hero .btn-primary:hover {
    background-color: #0056b3;
}